import React, { useEffect, useState } from "react";
import { Drawer, Form, Input, InputNumber, Button, Select } from "antd";
import { createFixedCharges } from "./api"; // Ensure you have a method to get users

interface FixedChargeDrawerProps {
  visible: boolean;
  onClose: () => void;
  userId: string;
  refreshData: () => void;
  users: { label: string; value: string }[];
}

const FixedChargeDrawer: React.FC<FixedChargeDrawerProps> = ({ visible, onClose, userId, refreshData, users }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>(userId);

    const onFinish = async (values: any) => {
        console.log('Form values:', values);
        try {
        setLoading(true);
        const body = { ...values, userId: selectedUser };
        await createFixedCharges({ body ,setLoading });
        await refreshData();
        } catch (error) {
        console.error('Error creating fixed charge:', error);
        } finally {
        setLoading(false);
        onClose();
        }
    };

    const handleValuesChange = () => {
        const amount = parseFloat(form.getFieldValue('amount'));
        
        if (!isNaN(amount)) {
        const taxValue = parseFloat((amount * 0.18).toFixed(2)); // Tax is 18%
        const total = parseFloat((amount + taxValue).toFixed(2)); 
    
        console.log('Tax:', taxValue, 'Total:', total);
    
        form.setFieldsValue({
            tax: taxValue,
            totalAmount: total,
        });
        }
    };
    const handleUserChange = (value: string) => {
        setSelectedUser(value);
    };

  return (
    <Drawer
      title="Add Fixed Charge"
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={handleValuesChange}>
        <Form.Item name="userId" label="User" rules={[{ required: true, message: 'Please select a user' }]}>
          <Select
            options={users}
            value={selectedUser}
            onChange={handleUserChange}
            style={{ width: '55%' }}
          />
        </Form.Item>
        <div className="flex flex-col gap-2">
       
        <div className="flex gap-2">
        <Form.Item name="noticeType" label="Notice Type" rules={[{ required: true, message: 'Please enter notice type' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="pageies" label="Number of page " rules={[{ required: true, message: 'Please enter pageies' }]}>
          <InputNumber />
        </Form.Item>
        </div>
        <div className="flex gap-2">
        <Form.Item name="amount" label="Amount (with out tax)" rules={[{ required: true, message: 'Please enter amount' }]}>
          <Input style={{ width: '60%' }} />
        </Form.Item>
        <Form.Item name="tax" label="Tax">
        <InputNumber disabled style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item name="totalAmount" label="Total Amount">
        <InputNumber  disabled style={{ width: '50%' }} />
        </Form.Item>
        </div>
        {/* <Form.Item name="serviceType" label="Service Type">
            <Input />
        </Form.Item> */}
        {/* <Form.Item name="provider" label="Provider" rules={[{ required: true, message: 'Please enter provider' }]}>
          <Input />
        </Form.Item> */}
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default FixedChargeDrawer;
