import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Button,Form, Select,Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IServiceProvidersListProps } from '../../../../../interfaces/serviceProvider';
import { FaDownload } from 'react-icons/fa';
import moment from 'moment';
import { acknowledgementLabelLink, createBulkShipment,  deleteBulkShipment,  getAddressLableLink,  getShipmentLableLink, retryFailedUpload } from '../../api';
import OrderDrawer from '../OrderDrawer';
import { Typography } from 'antd';
import Modal from 'antd/es/modal/Modal';
import {  DeleteOutlined, SnippetsFilled } from '@ant-design/icons';

const { Paragraph } = Typography;
interface IHandlePortalServiceability {
  currentPortalName: string;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  bulkOrderList: IServiceProvidersListProps[];
  setActiveTab: Dispatch<SetStateAction<string>>;
  activeKey:string;
  userId:string;
  setSelectedRaws: Dispatch<SetStateAction<any[]>>;
}
const HandleBulkOrderList: FC<IHandlePortalServiceability> = ({
  isLoading,
  setIsLoading,
  bulkOrderList,
  setActiveTab,
  activeKey,
  userId,
  setSelectedRaws
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedBulkId, setSelectedBulkId] = useState('');
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [providerForm] = Form.useForm();
  const [serviceTypeOption, setServiceTypeOption] = useState([{label:"",value:""}]);
  const [bulkId,setBulkId]=useState<string>('');
  const [bulkOrdersList, setBulkOrdersList] = useState<{orders: { [key: string]: any }[];totalPage: number;}>({orders: [],totalPage: 0});
  const start = (currentPage - 1) * pageLimit;
  const end = start + pageLimit;
  const currentPageData = bulkOrdersList?.orders.slice(start, end);
  const [cartStatus, setCartStatus] = useState<number>();
  useEffect(() => {
    setBulkOrdersList({ orders: bulkOrderList, totalPage: 0 });
  }, [bulkOrderList]);

  const handleBulkOrderIdClick = (bulkId:string) => {
    setSelectedBulkId(bulkId);
    setDrawerVisible(true);
  };

  const onClose = () => {
    setOpen(false);
  }

  const onCloseDelete = () => {
    setOpenDelete(false);
  }

  const viewOrderDetails = async (orderType: string, record: any) => {
    setSelectedBulkId(record._id);
    if(orderType === 'success'){
      setCartStatus(2)
      setDrawerVisible(true);
    }else if(orderType === 'processing'){
      setCartStatus(1)
      setDrawerVisible(true);
    }else if(orderType === 'dispatchedOrders'){
      setCartStatus(5)
      setDrawerVisible(true);
    }
    else{
      setCartStatus(0)
      setDrawerVisible(true);
    }
  };

  const retryOrder =async (record:any) => {
    retryFailedUpload(record._id);
  };


  const columns: ColumnsType<any> = [
    {
      title: 'Bulk Order Id',
      dataIndex: '_id',
      fixed: 'left',
      width: 150,
      key: '_id',
      render: (orderId) => (      
        <Paragraph  copyable={{ text: orderId }}  onClick={() => handleBulkOrderIdClick(orderId)} style={{color:"#1677ff",textDecoration:"underline"}}><b>
          {orderId ? orderId : 'NA'}</b>
        </Paragraph>
      ),
    },  
    {
      dataIndex: 'bulkOrderDate',
      key: 'bulkOrderDate',
      title: 'Date',
      width: 150,
       align: 'center',
      render: (date) => {
        const bulkOrderDate = moment(date).format('DD-MM-YYYY hh:mm:ss');
        return (
          <div>{bulkOrderDate==='Invalid date' ?  '-':bulkOrderDate}</div>
        );
      },
    },
    {
      title: 'Total Orders',
      dataIndex: 'orders',
      key: 'orders',
      width: 150,
      align: 'center',
      render: (orders) => <div>{orders}</div>,
    },
    {
      title: 'Shipment (ZIP)',
      key: 'bulkShipmentLink',
      dataIndex: 'bulkShipmentLink',
      width: 150,
      align: 'center',
      render: (orders:any) => {
        return (
          	<Button
                icon={<FaDownload />}
                href={orders}
                target="_blank"
                className="primary-button">
                Download
			      </Button>
        )
      }
    },
    
  ];

  const providerName: {
    label: string;
    value: string;
    serviceType?: {
      label: string;
      value: string;
    }[];
  }[] = [
    {
      label: 'India Post',
      value: 'India Post',
      serviceType: [
      {
        label: "Self Drop",
        value: "Self Drop"
      },
    ]
    },
    {
      label: 'DTDC',
      value: 'DTDC',
      serviceType: [
      {
        label: "Premium",
        value: "Premium"
      },
    ]
    }
  ];
 
  if(activeKey === 'Open To Ship'){
    columns.push(
      {
        title: 'Ship Now',
        key: 'shipNow',
        dataIndex: 'shipNow',
         align: 'center',
         width: 80,
        render: (text, record, index)  => {
          const showModal = (value:any,) => {
			setBulkId(record._id)
            setOpen(true);
          };
          
          return (
            <>
            <Button
                onClick={showModal}
                className="primary-button">
                Ship Now
            </Button>
			
            </>
          )
        }
      },
      {
        title: 'Delete',
        key: 'Delete',
        dataIndex: 'delete',
         align: 'center',
         width: 40,
        render: (text, record, index)  => {
          const showModal = (value:any,) => {
            setBulkId(record._id)
            setOpenDelete(true);
          };
          return (
            <>
            <DeleteOutlined style={{ color:  '#1890ff' }} onClick={showModal} />
            </>
          )
        }
      }
  )
}

if(activeKey === 'Shipment Placed'){
  columns.splice(5,0,
    {
      title: 'Lable (PDF)',
      key: 'bulkLableLink',
      width: 150,
      align: 'center',
      render: (text, record, index) => {
        const getShipmentLabel = async(bulkId:string) => {
          setIsLoading(true);
          const shipmentLableDetails :any= await getShipmentLableLink({bulkId:bulkId});
          if(shipmentLableDetails?.data?.url){
            window.open(shipmentLableDetails?.data.url,'_blank'); 
          }
          setIsLoading(false);
        }
        return (
          	<Button
                onClick={()=>getShipmentLabel(record._id)}
                icon={<SnippetsFilled />}
                target="_blank"
                className="primary-button"
                disabled={record.failedOrders === record.orders}>
                Lable Download
			      </Button>
        )
      }
    },
  )
  columns.splice(3,0,
   {
    title: 'Success Orders',
    dataIndex: 'successOrders',
    key: 'successOrders',
    width: 150,
    align: 'center',
    render: (successOrders, record) => (
      <Tag color="green" style={{cursor:"pointer"}} onClick={() => viewOrderDetails('success', record)}> {successOrders}</Tag>
    )
  },
  {
    title: 'Failed Orders',
    dataIndex: 'failedOrders',
    key: 'failedOrders',
    width: 150,
    align: 'center',
    render: (failedOrders, record) => (
      <div>
      <Tag color="red" style={{ cursor: "pointer" }} onClick={() => viewOrderDetails('failed', record)}>
        {failedOrders}
      </Tag>
      <Button type="link" onClick={() => retryOrder(record)} style={{ marginLeft: 8 }}>
        Retry Order
      </Button>
    </div>
    )
  },
  )
}

if(activeKey === 'To Be Dispatched'){

  columns.splice(3, 0,
    {
      title: 'Proccess Orders',
      dataIndex: 'inProccessOrders',
      key: 'inProccessOrders',
      width: 100,
      align: 'center',
      render: (inProccessOrders, record) => (
        <Tag color="yellow" style={{cursor:"pointer"}} onClick={() => viewOrderDetails('processing', record)}> {inProccessOrders}</Tag>
      )
    },
    {
      title: 'Dispatched Orders',
      dataIndex: 'toBeDispatchedOrders',
      key: 'toBeDispatchedOrders',
      width: 100,
      align: 'center',
      render: (toBeDispatchedOrders, record) => (
        <div>
          <Tag color="green" style={{ cursor: "pointer" }} onClick={() => viewOrderDetails('dispatchedOrders', record)}>
            {toBeDispatchedOrders}
          </Tag>
        </div>
      )
    },
    {
      title: 'Address Lable',
      key: 'bulkAddressLabelLink',
      width: 150,
      align: 'center',
      render: (text, record, index) => {

        const getShipmentLabel = async(bulkId:string) => {
          setIsLoading(true);
          const shipmentLableDetails :any= await getAddressLableLink({bulkId:bulkId});
          if(shipmentLableDetails?.data?.url){
            window.open(shipmentLableDetails?.data.url,'_blank'); 
          }
          setIsLoading(false);
        }
        return (
          <Button
            onClick={()=>getShipmentLabel(record._id)}
            icon={<SnippetsFilled />}
            target="_blank"
            className="primary-button"
            disabled={record.failedOrders === record.orders || (activeKey === 'To Be Dispatched' && record.inProccessOrders > 0)}>
            Lable Download
          </Button>
        )
      }
    },
    {
      title: 'Dl Acknowledge Label',
      key: 'bulkAddressLabelLink',
      width: 130,
      align: 'center',
      render: (text, record, index) => {

        const getShipmentLabel = async(bulkId:string) => {
          setIsLoading(true);
          const shipmentLableDetails :any= await acknowledgementLabelLink({bulkId:bulkId});
          if(shipmentLableDetails?.data?.url){
            window.open(shipmentLableDetails?.data.url,'_blank'); 
          }
          setIsLoading(false);
        }
        return (
          <Button
            onClick={()=>getShipmentLabel(record._id)}
            icon={<SnippetsFilled />}
            target="_blank"
            className="primary-button"
            disabled={record.failedOrders === record.orders || (activeKey === 'To Be Dispatched' && record.inProccessOrders > 0)}>
            Lable Download
          </Button>
        )
      }
    },
    {
      title: 'Shipment (XLSX)',
      key: 'bulkExcelLink',
      width: 150,
      align: 'center',
      render: (orders:any) => {
        return (
          <Button
            icon={<FaDownload />}
            href={orders.bulkExcelLink}
            target="_blank"
            className="primary-button">
            Download
          </Button>
        )
      }
    }
  );
  columns.pop();
}


const handleSubmit = async (formValues:any) => {
  setIsLoading(true);
  setOpen(false);
  await createBulkShipment({bulkId:bulkId,serviceProvider:formValues.serviceProvider,serviceType:formValues.serviceType})
  setIsLoading(false);
};

const setServiceOption = (value:any,selectedOption:any) => {
	if(selectedOption&&selectedOption.serviceType){
		setServiceTypeOption(selectedOption.serviceType);
	}
}

const onDelete = async () => {
  setIsLoading(true);
  setOpenDelete(false);
  await deleteBulkShipment({userId,bulkId})
  setIsLoading(false);
}

const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    setSelectedRaws(selectedRows)
  }
};

  return (
    <>
      <div>
        <div className="mb-4 flex items-center justify-between"/>
        <div>
        <Table
        rowSelection={rowSelection}
        bordered
        columns={columns}
        loading={isLoading}
        rowKey={'_id'}
        scroll={{ x: 1616 }}
        pagination={false}
        dataSource={currentPageData}
      />

    
    </div>
    <div>
      <OrderDrawer visible={drawerVisible} bulkId={selectedBulkId} userId={userId}  cartStatus={cartStatus} setDrawerVisible={setDrawerVisible} ></OrderDrawer>
    </div>
      </div>

      <Modal
        open={open}
        onCancel={onClose}
        footer={false}>
        <div >

          <Form form={providerForm} layout="vertical" id="addProviderForms" onFinish={handleSubmit}>
            <div className="flex flex-col gap-2">
              <p>Provider name :</p>
              <Form.Item
                name="serviceProvider"
                rules={[
                  {
                    required: true,
                    message: 'Please enter provider name.'
                  }
                ]}>
                <Select placeholder="Select provider name" options={providerName} onChange={setServiceOption}/>
              </Form.Item>
            </div>

            <div className="flex flex-col gap-2">
              <p>Service Type :</p>
              <Form.Item
                name="serviceType"
                rules={[{ required: true, message: 'Please enter service type' }]}>
               <Select placeholder="Select service type name" options={serviceTypeOption} />
              </Form.Item>
            </div>

            <div className="my-5 flex justify-end gap-5">
              <Button onClick={onClose}>Cancel</Button>
              <Button htmlType="submit" form="addProviderForms" className="px-10 primary-button">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Modal>


      <Modal
        open={openDelete}
        onCancel={onCloseDelete}
        footer={false}>
        <div >
            <div className="flex flex-col gap-2">
              <p>Are you sure you want to delete?</p>
            </div>
            <div className="my-5 flex justify-end gap-5">
              <Button onClick={onCloseDelete}>Cancel</Button>
              <Button onClick={onDelete}>
                Submit
              </Button>
            </div>
        </div>
      </Modal>
    </>
  );
};

export default HandleBulkOrderList;
